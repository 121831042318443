<template>
	<v-lazy :min-height="200" :options="{ threshold: 0.5 }" transition="fade-transition">
		<v-card elevation="0" class="blog-card overflow-hidden hover-card" @mouseover="isHovered = true" @mouseleave="isHovered = false">
			<div class="position-relative mb-6">
				<NuxtImg loading="lazy" format="webp" quality="75" :src="imageSrc" :alt="destinationText" class="img-fluid" fit="cover" />
				<div class="date-badge text-small bg-info-grediant">
					<span>{{ destinationText }}</span>
				</div>
			</div>
		</v-card>
	</v-lazy>
</template>

<script>
export default {
	props: {
		imageSrc: {
			type: String,
			required: true,
		},
		destinationText: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			isHovered: false,
		};
	},
};
</script>

<style scoped>
.hover-card {
	transition: transform 0.3s ease-in-out;
}

.date-badge span {
	font-size: 18px !important;
}

.hover-card:hover {
	transform: scale(1.05);
}

img {
	height: 250px;
	object-fit: cover;
	width: 100%;
}
</style>
